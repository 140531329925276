// 自定义导航栏
<template>
    <div class="navigation">
        <div v-if="type == 0">
            <div v-if="navList.length == 0">
                <el-empty description="暂无数据"></el-empty>
            </div>
            <div v-else>
                <div class="nav-box" v-for="(item,i) in navList" :key="i">
                    <div :class="selectedCode == item.id?'onSelected':'notSelected'" class="nav-Menu div-hover" @click="openOptions(item.id,item.children.length,item)">
                        <div class="line-box">
                            <div class="left-line"></div>
                            <img class="center-img" :src="require(`@/assets/images/functionIcon/data.png`)" alt="">
                        </div>
                        <div class="options-height">
                            {{ item.name }}
                        </div>
                        <div class="img-out">
                            <img class="right-go-other" v-if="item.children.length != 0" :class="codeList.indexOf(item.id)!=-1?'rotate':''" :src="require(`@/assets/images/functionIcon/open.png`)" alt="">
                        </div>
                    </div>
                    <transition name="plus-icon">
                        <template v-if="codeList.indexOf(item.id)!=-1&&type == 0">
                            <div class="options-box-other bg-color" >
                                <div class=" div-hover" v-for="(sonItem,sonI) in item.children" :key="sonI">
                                    <div class="options-height-box" @click="openOptions(sonItem.id,sonItem.children.length,sonItem)" :style="selectedCode == sonItem.id?'color: #637DFF;':'color: #707070;'">
                                        <div class="line"></div>
                                        <div class="options-height">
                                            {{ sonItem.name }}
                                        </div>
                                        <div class="img-out">
                                            <img class="right-go-other" v-if="sonItem.children.length != 0" :class="codeList.indexOf(sonItem.id)!=-1?'rotate':''" :src="require(`@/assets/images/functionIcon/open.png`)" alt="">
                                        </div>
                                    </div> 
                                    <transition name="plus-icon">
                                        <template v-if="codeList.indexOf(sonItem.id)!=-1&&type == 0">
                                            <div class="options-box-other bg-color-othrt" >
                                                <div class=" div-hover" v-for="(grandsonItem,grandsonI) in sonItem.children" :key="grandsonI">
                                                    <div class="options-height-box" @click="openOptions(grandsonItem.id,grandsonItem.children.length,grandsonItem)" :style="selectedCode == grandsonItem.id?'color: #637DFF;':'color: #707070;'">
                                                        <div class="line"></div>
                                                        <div class="options-height">
                                                            {{ grandsonItem.name }}
                                                        </div>
                                                        <div class="img-out">
                                                            <img class="right-go-other" v-if="grandsonItem.children.length != 0" :class="codeList.indexOf(grandsonItem.id)!=-1?'rotate':''" :src="require(`@/assets/images/functionIcon/open.png`)" alt="">
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                        </template>
                                    </transition>
                                </div>
                            </div>
                        </template>
                    </transition>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="nav-box-other" v-for="(item,i) in navList" :key="i" :style="selectedCode == item.id?'background: #cbdbff;':'background: #fff;'">
                <img class="center-img" :src="require(`@/assets/images/functionIcon/data.png`)" alt="">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        navList: {
            default: []
        },
        type: {
            default: null
        }
    },
    data () {
        return {
            codeList: [],//导航父级展开
            selectedCode: '',//导航父级选中
            navTagList:[],//导航列表
        }
    },
    created() {

    },
    watch: {
        navList(val,newval){
            if(newval){
                this.initSelected(this.navList)
            }
        }
    },
    mounted() {
        
    },
    methods: {
        // 初始化选中
        initSelected(list){
            let items = null
            if(list[0].children.length != 0){
                this.codeList.push(list[0].id)
                this.selectedCode = list[0].children[0].id
                items = list[0].children[0]
                if(list[0].children[0].children.length != 0){
                    this.codeList.push(list[0].children[0].id)
                    this.selectedCode = list[0].children[0].children[0].id
                    items = list[0].children[0].children[0]
                }
            }else{
                this.selectedCode = list[0].id
                items = list[0]
            }
            let navId = items.path.split(',')
                navId.push(this.selectedCode)
            this.assemblyList(navId,this.navList)
        },
        // 父级展开 or 选中
        openOptions(i,l,t){
            //判断是否有子级
            if( l == 0 ){
                this.selectedCode = i
                // 组装导航栏
                // this.navTagList = [] 
                let navId = t.path.split(',')
                navId.push(i)
                this.assemblyList(navId,this.navList)
                if( i == 16 ){
                    this.$router.push('/dashboard')
                }else if(i == 21){
                    this.$router.push('/system/menu')
                }else{
                    this.$router.push('/setup')
                }
            }else{
                if(this.codeList.indexOf(i) == -1){
                    this.codeList.push(i)
                }else{
                    this.codeList.splice(this.codeList.indexOf(i), 1); 
                }
            }
        },
        // 组装导航list
        assemblyList(path,list){
            this.navTagList = []
            list.forEach((it,i) => {
                if(path.indexOf(it.id) != -1){
                    this.navTagList.push(it)
                    it.children.forEach((its,ts)=>{
                        if(path.indexOf(its.id) != -1){
                            this.navTagList.push(its)
                            its.children.forEach((itsx,sx)=>{
                                if(path.indexOf(itsx.id) != -1){
                                    this.navTagList.push(itsx)
                                }
                            })
                        }
                    })
                }
            })
            this.$emit("updateList", this.navTagList);
        }
    }
}
</script>

<style lang="scss" scoped>
.navigation{
    .nav-box-other{
        height: 3.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.nav-box{
    .onSelected{
      background: #cbdbff;
      color: #637DFF;
      .line-box{
        width: 3.75rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        .left-line{
        width: .3125rem;
        height: 1.25rem;
        background: #637DFF;
        border-radius: .125rem;
        margin-left: .625rem;
        }
      }
    }
    .notSelected{
      color: #707070;
      .line-box{
        width: 3.75rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        .left-line{
          margin-left: .625rem;
          width: .3125rem;
          height: 1.25rem;
        }
      }
    }
    .nav-Menu{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      height: 3.75rem;
    }
    .div-hover{
      cursor: pointer;
    }
  }
.line{
    width: 3.75rem;
    height: 1.25rem;
}
.right-go-other{
    margin-right: .625rem;
    transition: transform .4s;
}
.options-box-other{
    background: #e7e7e7;
    .options-height-box{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }
}
.bg-color{
    background: #f7f7f7;
}
.bg-color-othrt{
    background: #e7e7e7;
}
.rotate{
    transform:rotate(90deg)
}
.plus-icon-enter-active{
    transition: opacity .4s;
}
.plus-icon-enter{
    opacity: 0;
}
.plus-icon-leave-active{
    transition: opacity .4s;
}
.plus-icon-leave-to{
    opacity: .5;
}
.options-height{
    height: 3.75rem;
    width: 8.125rem;
    line-height: 3.75rem;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    // color: #707070;
}
.center-img{
    width: 1rem;
    height: 1rem;
    margin-right: .3125rem;
}
.img-out{
    width: 1.5625rem;
    height: 1.5625rem;
}
</style>