// 公共组件
<template>
    <div>
      <el-row ref="row">
        <el-col :span="3">
          <!-- logo区域 -->
          <div class="left-box">
            <div class="logo-box top-height">
              <img class="logo-img" :src="require(`@/assets/images/logo.png`)" alt="" v-if="switchData.type == 0">
              <img class="logo-img-other" :src="require(`@/assets/images/logo-mini.png`)" alt="" v-else>
            </div>
            <!-- 导航区域 -->
            <div class="nav-box">
              <navigation :navList='titleList' :type='switchData.type' ref="navigation" @updateList="getList"></navigation>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="right-box">
            <div class="main top-height">
              <div class="search-box">
                  <el-row>
                    <el-col :span="1">
                      <div @click="switchType" class="switch-box">
                        <img class="tops-img" :class="rotating == 1?'rotating-img':''" :src="require(`@/assets/images/functionIcon/nav.png`)" alt="">
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div class="nav-title-box">
                        <!-- 导航条 -->
                        <div>
                          <el-breadcrumb separator="/">
                            <el-breadcrumb-item v-for="(navItem,navI) in navTagData" :key="navI" :to="{ path: 'navItem.route' }">{{navItem.name}}</el-breadcrumb-item>
                          </el-breadcrumb>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="11">
                        <div class="right-nav">
                          <a-space align="center" :size="18">
                          <div class="search-input">
                            <el-autocomplete
                              class="inline-input"
                              v-model="search"
                              :fetch-suggestions="querySearch"
                              placeholder="请输入搜索内容"
                              suffix-icon="el-icon-search"
                              @select="handleSelect"
                            >
                            </el-autocomplete>
                          </div>
                          <a-badge :dot="false">
                            <i class="iconfont icon-copy-4-10 img-icon"/>
                          </a-badge>
                          <a-badge :dot="true">
                            <i class="iconfont icon-copy-4-11 img-icon" @click="openDrawer"/>
                          </a-badge>
                          <a-badge :dot="false">
                            <i class="iconfont icon-copy-4-40 img-icon"/>
                          </a-badge>
                          <div class="user-box">
                            <!-- <img class="user-img tops-img" :src="require(`@/assets/images/user.png`)" alt=""> -->
                            <div class="user-data">
                                <div class="name">{{ $store.getter.user.name }}</div>
                                <div class="role">管理员</div>
                            </div>
                          </div>
                          </a-space>
                        </div>
                    </el-col>
                  </el-row>
              </div>
            </div>
            <div class="main-bottom">
              <div class="main-details">
                <div class="rolling-box">
                  <div class="none-box" v-if="titleList.length == 0&&switchData.type == 0">
                    <el-empty description="暂无数据"></el-empty>
                  </div>
                  <div v-else>
                    <router-view/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div>
        <a-drawer
          placement="right"
          :closable="false"
          v-model:visible="drawer"
          :bodyStyle="{padding:0}"
          :width="400"
          :maskStyle="{opacity:0,animation:none}"
        >
          <div class="the-drawer">
            <div class="drawer-title" v-for="(tag,t) in tagList" :key="t" :class="isSelTge == t?'bottom-line':''" @click="sleTag(t)">{{ tag.title }}</div>
          </div>
          <div>
            <el-empty description="暂无数据"></el-empty>
          </div>
        </a-drawer>
      </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, getCurrentInstance, UnwrapRef, toRaw } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import user from '@/api/system/user'
import navigation from '@/components/navigation'

export default defineComponent({
  components: {
    navigation
  },

  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onMounted(() => {
      getMenuList()
      getUserInfo()
      load()
    })

    let titleList = ref([]);
    const getMenuList = () => {
      user.getUserMenuTree().then((res) => {
       titleList = res.data[0].children
       router.push('/dashboard')
      })
    }

    const getUserInfo = () => {
      user.getUser({}).then((res) => {
        store.dispatch('setUser', res.data.data)
      })
    }

    // 打开抽屉
    let tagList = ref([ // 弹出抽屉选项卡
      {
        title:'选项1'
      },
      {
        title:'选项2'
      },
      {
        title:'选项3'
      }
    ])
    let drawer = ref(false)
    const openDrawer = () => {
      drawer.value = true
    }

    // 选中标签
    let isSelTge = ref(0)
    const sleTag = (i) => {
      isSelTge.value = i
    }

    let restaurants = ref([])
    const querySearch = (queryString, cb) => {
      var restaurants = restaurants;
      var results = queryString ? restaurants.filter(createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results);
    }

    const createFilter = (queryString) => {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    }

    let search = ref('')

    const loadAll = () => {
      return [
        { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
        { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
        { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
        { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
        { "value": "胖仙女纸杯蛋糕（上海凌空店）", "address": "上海市长宁区金钟路968号1幢18号楼一层商铺18-101" },
        { "value": "贡茶", "address": "上海市长宁区金钟路633号" },
        { "value": "豪大大香鸡排超级奶爸", "address": "上海市嘉定区曹安公路曹安路1685号" },
        { "value": "茶芝兰（奶茶，手抓饼）", "address": "上海市普陀区同普路1435号" },
        { "value": "十二泷町", "address": "上海市北翟路1444弄81号B幢-107" },
      ];
    }

    const handleSelect = (item) => {
      console.log(item);
    }

    // 更新list数据
    let navTagData = ref([])
    const getList = (list) => {
      navTagData.value = list
    }

    return {
      openDrawer,
      search,
      drawer,
      tagList
    }
  }

})
</script>

<style lang="scss" scoped>
  .el-row {
    height: 100%;
  }
  .top-height{
    height: 70px;
  }
  .logo-box{
    .logo-img{
      width: 48%;
      height: 45%;
      margin-left: 3.5625rem;
      margin-top: 1.125rem;
    }
    .logo-img-other{
      width: 40%;
      height: 50%;
      margin-left: 1.55rem;
      margin-top: 1rem;
    }
  }
  .rotate{
    transform:rotate(90deg)
  }
  .main{
    width: 100%;
    background-image: linear-gradient(#AEBAF8, #D8DDF8);
    border-radius: 1.25rem 0 0 0;
    padding: 12px 15px 0 15px;
    .search-box{
      border-radius: .9375rem;
      height: 53px;
      background: #fff;
      padding: 0 1rem;
      box-shadow: 0px 0px 6px 0px #d3d6e2; 
      .el-row{
        display: flex;
        align-items: center;

      }
    }
  }
  .table-bar{
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    .nav-title{
      margin-right: 1.875rem;
      font-size: 1.125rem;
      font-weight: 600;
    }
  }
  .right-nav{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    // .search-input{
    //   // width: 15.975rem;
    // }
    .img-icon{
      cursor: pointer;
      color: #7594FF; 
      font-size: 1.5rem;
    }
    .user-box{
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      .user-img{
        border-radius: 50%;
        border: solid .125rem #707070;
        width: 2.4125rem;
        height: 2.4125rem;
      }
      .user-data{
        margin-left: .7rem;
        .name{
          color: #24252F;
          font-size: .815rem;
          font-weight: 500;
        }
        .role{
          color: #7594FF;
          font-size: .615rem;
          font-weight: 500;
        }
      }
    }
  }
  .main-bottom{
    width: 100%;
    height: calc(100vh - 70px); 
    background-image: linear-gradient(#D8DDF8, #ECEEF8 10%, #ECEEF8);
    padding: 12px 15px 12px 15px;
    .main-details{
      background: #fff;
      height: calc(100vh - 100px); 
      border-radius: .9375rem;
      box-shadow: 0px 0px 6px 0px #d3d6e2; 
      padding: 1rem;
      padding-right: 0;
      .none-box{
        padding: 1rem 0 0 1rem;
      }
    }
  }
  .rolling-box{
    width: 100%;
    overflow-y: overlay;
    height: calc(100vh - 130px); 
    padding-right: 1.5625rem;
  }
  // 消息抽屉
  .the-drawer{
    background: #E8ECFF;
    height: 4.375rem;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .drawer-title{
    font-size: 1.125rem;
    color: #637DFF;
    width: 7.5rem;
    height: 4.375rem;
    line-height: 4.375rem;
    text-align: center;
    cursor: pointer;
  }
  .bottom-line{
    border-bottom: solid .1875rem #7596FF;
  }
  .switch-box{
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  .nav-title-box{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .nav-box {
    overflow-y: overlay;
  }
  .right-box,.left-box{
    transition: width .4s;
  }
  .right-box::-webkit-scrollbar {
    width: 0;
  }
  .tops-img{
    width: 1.75rem;
    height: 1.75rem;
  }
  .rotating-img{animation:xz 1s linear infinite ;}
  @keyframes xz{
    from  {transform:rotate(0deg);}
    to{transform:rotate(360deg);}
  }
</style>

<style lang="scss">
  .right-nav .search-input .inline-input {
    .el-input__inner {
      border-radius: 1.25rem !important;
      border-color: #D8DDF8;
      height: 2.125rem;
      background-color: #F3F5FF;
    }
    .el-input__inner:focus {
      outline: 0;
      border-color: #7A90FF;
    }
  }
  .right-nav .ant-badge {
    margin-top: 5px !important;
    sup {
      width: 6.5px !important;
    }
  }
</style>